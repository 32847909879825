import React from 'react'
import _ from 'loadsh'
import moment from 'moment'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import { Modal, message } from 'antd'
import { pk } from './config'
import NodeRSA from 'node-rsa'
import { JSEncrypt } from 'jsencrypt'

const { confirm } = Modal

// await 错误处理方法
export async function awaitWrap (promise) {
  return await promise
    .then((data) => [null, data])
    .catch(err => [err, null])
}

// 验证文件是否为空
export function validateFileRequire (notice = '') {
  return {
    validator (_, value) {
      if (!value) {
        return Promise.resolve()
      } else if (value.fileList.length === 0) {
        return Promise.reject(new Error(notice))
      }
      return Promise.resolve()
    }
  }
}

// 获取文件上传的配置
export function getUploadProps (formRef, accept, key) {
  const keys = Object.keys(this.state)
  const hasKey = key && keys.includes(key)
  return {
    onRemove: file => {
      this.setState(state => {
        const l = state[hasKey ? key : 'fileList']
        const index = l.indexOf(file)
        const newFileList = l.slice()
        newFileList.splice(index, 1)
        const o = {}
        if (hasKey) {
          o[key] = newFileList
        } else {
          o.fileList = newFileList
        }
        return o
      })
    },
    beforeUpload: file => {
      if (accept) {
        const l = accept.split(',')
        const i = file.name.lastIndexOf('.')
        const n = file.name.slice(i)
        if (!l.includes(n)) {
          message.warning(`请选择${l}的文件`)
          return false
        }
      }
      this.setState(state => {
        const fileList = [...state[hasKey ? key : 'fileList'], file]
        const o = {}
        if (hasKey) {
          o[key] = fileList
        } else {
          o.fileList = fileList
        }
        return o
      })
      return false
    },
    fileList: this.state[hasKey ? key : 'fileList'],
    accept
  }
}

// 获取文件上传的配置
export function getUploadPropsFn (formRef, accept, fileList, setFileList, multiple) {
  return {
    onRemove: file => {
      const index = fileList.indexOf(file)
      const newFileList = fileList.slice()
      newFileList.splice(index, 1)
      setFileList(newFileList)
    },
    beforeUpload: (file, files) => {
      if (accept) {
        if (multiple) {
          for (let j = 0; j < files.length; j++) {
            const l = accept.split(',')
            const i = files[j].name.lastIndexOf('.')
            const n = files[j].name.slice(i)
            if (!l.includes(n)) {
              message.warning(`请选择${l}的文件`)
              return false
            }
            setFileList([...fileList, ...files])
          }
        } else {
          const l = accept.split(',')
          const i = file.name.lastIndexOf('.')
          const n = file.name.slice(i)
          if (!l.includes(n)) {
            message.warning(`请选择${l}的文件`)
            return false
          }
          setFileList([...fileList, file])
        }
      }
      return false
    },
    fileList,
    accept
  }
}

// 仲裁请求文本分项
export function splitItem (text) {
  text = text.trim()
  const cnNum = '一二三四五六七八九十' // 中文数字
  const pre = '\\u4e00-\\u9fa5a-zA-Z#\\-：:\'"‘’“”' // 序号前不能接的字符
  const numPre = `${pre}\\d\\.` // 阿拉伯数字序号前不能接的字符
  const split = '、,\\.，．' // 序号后的分隔符
  let nReplace = '$2$3$4$5$6$7\n'
  // 综合判断中文和阿拉伯数字序号
  let r = `(^[${cnNum}\\d][${split}]|(?=[^${numPre}]\\d{3}[${split}])(.)....|(?=[^${numPre}]\\d{2}[${split}])(.)...|(?=[^${numPre}]\\d[${split}])(.)..|(?=[^${pre}][${cnNum}]{3}[${split}])(.)....|(?=[^${pre}][${cnNum}]{2}[${split}])(.)...|(?=[^${pre}][${cnNum}][${split}])(.)..)(?![${cnNum}\\d]+)`
  if (cnNum.indexOf(text[0]) !== -1) { // 起始为中文序号时，只判断中文序号
    r = `(^[${cnNum}][${split}]|(?=[^${pre}][${cnNum}]{3}[${split}])(.)....|(?=[^${pre}][${cnNum}]{2}[${split}])(.)...|(?=[^${pre}][${cnNum}][${split}])(.)..)`
    nReplace = '$2$3$4\n'
  } else if (/\d/.test(text[0])) { // 起始为阿拉伯数字序号时，只判断阿拉伯数字序号
    r = `(^\\d[${split}]|(?=[^${numPre}]\\d{3}[${split}])(.)....|(?=[^${numPre}]\\d{2}[${split}])(.)...|(?=[^${numPre}]\\d[${split}])(.)..)(?!\\d+)`
    nReplace = '$2$3$4\n'
  }
  const reg = new RegExp(r, 'gim')
  // const nRef = new RegExp(`(\\r\\n?|\\n)(?![${cnNum}]+[${split}])`, 'g')
  let result = text.replace(reg, nReplace).split('\n').map(li => li.trim()).filter(li => li)
  result = _.uniqWith(result, _.isEqual)
  return result
}

// 格式化金钱
export function formatMoney (v, empty = '无') {
  if (typeof v === 'number') {
    return v.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  }
  if (typeof v === 'string' && /^\d+(\.)?\d*$/.test(v)) {
    return Number(v).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  }
  return empty
}

// 格式化日期
export function formatDate (t, format = 'YYYY-MM-DD') {
  if (typeof t === 'number' && t > 0) {
    return moment(t * 1000).format(format)
  } else if (typeof t === 'string' && /^\d*$/.test(t)) {
    return moment(parseInt(t) * 1000).format(format)
  }
  return t
}

// 限制字符串长度
export function limitStr (str, limit = 50) {
  if (typeof str === 'string' && str.length > limit) {
    return str.slice(0, limit) + '...'
  }
  return str
}

// 二次确认
export function confirmAction (content, callback, cancel) {
  confirm({
    icon: <ExclamationCircleOutlined />,
    content,
    onOk () {
      callback()
    },
    onCancel () {
      cancel && cancel()
    }
  })
}

// 获取格式化的树
export function getFormatTreeData (v) {
  const stack = [...v]
  while (stack.length) {
    const o = stack.shift()
    if (o?.children instanceof Array) {
      delete o.key
      o.children.forEach(li => {
        stack.push(li)
      })
    }
  }
  return v
}

// 打印文档
export function $print (filepath) {
  const oldIframe = document.getElementById('print')
  if (oldIframe) {
    document.body.removeChild(oldIframe)
  }
  const hide = message.loading('文件加载中，准备打印', 120)
  const iframe = document.createElement('iframe')
  iframe.setAttribute('id', 'print')
  iframe.setAttribute('style', 'width: 0; height: 0; position: absolute; top: -500px; left: -500px;')
  iframe.setAttribute('src', filepath)
  iframe.onload = () => {
    hide()
    iframe.contentWindow && iframe.contentWindow.print()
  }
  document.body.appendChild(iframe)
}

// 公钥加密
export function compress (v) {
  // const key = new NodeRSA(pk, undefined, { encryptionScheme: 'pkcs1' })
  // const str = key.encrypt(v, 'base64')
  try {
    const encryptTool = new JSEncrypt()
    encryptTool.setPublicKey(pk)
    const str = encryptTool.encrypt(v)
    if (typeof str === 'string') {
      return str
    }
  } catch (e) {
    console.error(e)
  }
  return ''
}

// 公钥解密
export function decompress (v) {
  try {
    if (typeof v === 'string' && v) {
      const key = new NodeRSA()
      key.importKey(pk)
      const str = key.decryptPublic(v, 'utf8')
      if (typeof str === 'string') {
        return str
      }
    }
  } catch (e) {
    console.error(e)
  }
  return v || ''
}

// 格式化案件件证件号
export function formatCaseDataLicenseAndMobile (d) {
  const keys = ['applicant_agents', 'applicants', 'respondents', 'respondents_agents']
  keys.forEach(key => {
    const arr = d[key]
    if (arr instanceof Array && arr.length > 0) {
      arr.forEach(li => {
        if (typeof li.license === 'string' && li.license.length > 0) {
          try {
            li.license = decompress(li.license)
          } catch (e) {
            console.error(e)
          }
        }
        if (typeof li.mobile === 'string' && li.mobile.length > 0) {
          try {
            li.mobile = decompress(li.mobile)
          } catch (e) {
            console.error(e)
          }
        }
      })
    }
  })
  return d
}

export function compressLicense (o) {
  if (o && typeof o.license === 'string' && o.license.length > 0) {
    o.license = compress(o.license)
  }
}

export function decompressLicense (o) {
  if (o && typeof o.license === 'string' && o.license.length > 0) {
    o.license = decompress(o.license)
  }
}

export function compressListLicense (l) {
  if (l instanceof Array && l.length > 0) {
    l.forEach(li => {
      compressLicense(li)
    })
  }
}

export function decomporessListLicense (l) {
  if (l instanceof Array && l.length > 0) {
    l.forEach(li => {
      decompressLicense(li)
    })
  }
}

// 将秒数转换成时间
export function transformSecond (s) {
  const result = []
  const h = Math.floor(s / 3600)
  if (h > 0) {
    result.push(h < 10 ? `0${h}` : h)
  }
  s = s % 3600
  const m = Math.floor(s / 60)
  if (m > 0) {
    result.push(m < 10 ? `0${m}` : m)
  }
  s = Math.floor(s % 60)
  result.push(s < 10 ? `0${s}` : s)
  if (result.length === 1) {
    return `00:${result[0]}`
  }
  return result.join(':')
}
