import classNames from 'classnames'
import React, { useEffect, useRef, useState } from 'react'
import style from './Index.module.less'
import { getDataRequest, updateDataRequest } from './ajax'
import { awaitWrap } from '@/assets/js/tool'
import { message, Form } from 'antd'
import BaseInfoTag, * as BaseInfo from './components/BaseInfo/Index'
import BankTag, * as Bank from './components/Bank/Index'
import EmergencyContactTag, * as EmergencyContact from './components/EmergencyContact/Index'
import ContactTag, * as Contact from './components/Contact/Index'
import AchievementTag, * as Achievement from './components/Achievement/Index'
import ProfessionalTag, * as Professional from './components/ProfessionalTag/Index'
import ResearchTag, * as Research from './components/ResearchTag/Index'
import EducationTag, * as Education from './components/Education/Index'
import WorkTag, * as Work from './components/Work/Index'
import QualificationTag, * as Qualification from './components/Qualification/Index'
import CaseTag, * as Case from './components/Case/Index'
import _ from 'lodash'
import { useDispatch } from 'react-redux'
import { addScrollEvent, delScrollEvent } from '@/redux/actions'
import Statistic from './components/Statistic/Index'
import Annual from './components/Annual/Index'

const blockList = ['个人信息', '联系方式', '教育背景', '工作经历', '仲裁员任职资格', '擅长专业', '研究领域', '学术成果', '报酬接收账号', '紧急联系人', '办案信息']

async function initData (id, setData) {
  const hide = message.loading('加载中', 120)
  const [e, d] = await awaitWrap(getDataRequest(id))
  hide()
  if (e === null && d !== null) {
    setData(d)
  }
}

async function updateData (id, data) {
  const hide = message.loading('提交中')
  await awaitWrap(updateDataRequest(id, data))
  hide()
}

async function onSave (id, data) {
  const [e, d] = await awaitWrap(formatUpdateData(data))
  if (e !== null) {
    console.error(e)
  }
  updateData(id, d).catch(e => console.error(e))
}

async function formatUpdateData (d) {
  const keys = Object.keys(d)
  keys.forEach(key => {
    if (!d[key]) {
      d[key] = null
    }
  })
  BaseInfo.formatUpdateData(d)
  Contact.formatUpdateData(d)
  Education.formatUpdateData(d)
  Work.formatUpdateData(d)
  await Qualification.formatUpdateData(d)
  Professional.formatUpdateData(d)
  Achievement.formatUpdateData(d)
  Research.formatUpdateData(d)
  Bank.formatUpdateData(d)
  EmergencyContact.formatUpdateData(d)
  Case.formatUpdateData(d)
  return d
}

function initForm (data, formRef, fileSet, setEducationFileMap) {
  const o = {}
  BaseInfo.initFormData(data, o)
  Contact.initFormData(data, o)
  Education.initFormData(data, o, setEducationFileMap)
  Work.initFormData(data, o)
  Qualification.initFormData(data, o, fileSet)
  Professional.initFormData(data, o)
  Achievement.initFormData(data, o)
  Research.initFormData(data, o)
  Bank.initFormData(data, o)
  EmergencyContact.initFormData(data, o)
  Case.initFormData(data, o)
  formRef.current.setFieldsValue(o)
}

function scrollHandle (visible, divRef, setActiveAnchor) {
  const o = window.document.documentElement
  if (visible) {
    return undefined
  }
  if (o === null || !divRef.current) {
    return undefined
  }
  const top = o.scrollTop
  const cTop = o.offsetTop
  for (let i = 1; i < 11; i++) {
    const e = divRef.current.querySelector(`.block${i}`)
    if (e !== null) {
      if (e.offsetHeight > top - e.offsetTop + cTop) {
        setActiveAnchor(i - 1)
        break
      }
    }
  }
}

const scrollFn = _.debounce(scrollHandle, 100)

function Main ({ meta = { isOut: false } }) {
  const [data, setData] = useState(null)
  const formRef = useRef(null)
  const divRef = useRef(null)
  const [fileList1, setfileList1] = useState([])
  const [fileList2, setfileList2] = useState([])
  const [fileList3, setfileList3] = useState([])
  const [fileList4, setfileList4] = useState([])
  const [fileList5, setfileList5] = useState([])
  const [educationFileMap, setEducationFileMap] = useState({})
  const [activeAnchor, setActiveAnchor] = useState(0)
  const [id, setId] = useState('')
  const [statisticVisible, setStatisticVisible] = useState(false)
  const [annualVisible, setAnnualVisible] = useState(false)
  const dispatch = useDispatch()
  useEffect(() => {
    if (meta.id) {
      initData(meta.id, setData)
      setId(meta.id)
    }
  }, [meta])
  useEffect(() => {
    if (data && formRef.current) {
      initForm(data, formRef, [setfileList1, setfileList2, setfileList3, setfileList4, setfileList5, setEducationFileMap])
    }
  }, [data, formRef])
  useEffect(() => {
    function scroll () {
      scrollFn(statisticVisible, divRef, setActiveAnchor)
    }
    if (meta.isOut) {
      window.addEventListener('scroll', scroll)
    } else {
      dispatch(addScrollEvent({ name: `arbitrator-detail-${id}`, handle: scroll }))
    }
    scroll()
    return () => {
      if (meta.isOut) {
        window.removeEventListener('scroll', scroll)
      } else {
        dispatch(delScrollEvent(`arbitrator-detail-${id}`))
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, divRef, statisticVisible])
  return (
    <div ref={divRef} className={classNames(style.container, { [style.out]: meta.isOut })}>
      <div className={style['left-list']}>
        <a className={classNames(style['left-list-item'], { [style.active]: statisticVisible })} onClick={() => { setStatisticVisible(true); setAnnualVisible(false) }}><span>数字化信息展示</span></a>
        <a className={classNames(style['left-list-item'], { [style.active]: annualVisible })} onClick={() => { setStatisticVisible(false); setAnnualVisible(true) }}><span>年审情况</span></a>
        {blockList.map((li, i) => {
          return (
            <a
              className={classNames(style['left-list-item'], { [style.active]: activeAnchor === i && !statisticVisible && !annualVisible })} key={i} onClick={() => {
                setActiveAnchor(i)
                setAnnualVisible(false)
                setStatisticVisible(false)
                const o = divRef.current.querySelector(`.block${i + 1}`)
                if (o) {
                  window.document.documentElement.scrollTop = o.offsetTop
                }
              }}
            >
              <span>{li}</span>
            </a>
          )
        })}
      </div>
      {data && <div className={classNames(style['other-block'], { [style.hide]: !statisticVisible })}><Statistic id={id} name={data ? data.name : ''} /></div>}
      {data && <div className={classNames(style['other-block'], { [style.hide]: !annualVisible })}><Annual id={id} name={data ? data.name : ''} /></div>}
      <div className={classNames(style['right-container'], { [style.hide]: statisticVisible || annualVisible })}>
        <Form ref={formRef} onFinish={d => onSave(id, d)} colon={false}>
          <div className='block1'>
            <BaseInfoTag />
          </div>
          <div className='block2'>
            <ContactTag />
          </div>
          <div className='block3'>
            <EducationTag fileMap={educationFileMap} setFileMap={setEducationFileMap} />
          </div>
          <div className='block4'>
            <WorkTag />
          </div>
          <div className='block5'>
            <QualificationTag formRef={formRef} fileList1={fileList1} fileList2={fileList2} fileList3={fileList3} fileList4={fileList4} fileList5={fileList5} setfileList1={setfileList1} setfileList2={setfileList2} setfileList3={setfileList3} setfileList4={setfileList4} setfileList5={setfileList5} />
          </div>
          <div className='block6'>
            <ProfessionalTag />
          </div>
          <div className='block7'>
            <ResearchTag />
          </div>
          <div className='block8'>
            <AchievementTag />
          </div>
          <div className='block9'>
            <BankTag />
          </div>
          <div className='block10'>
            <EmergencyContactTag />
          </div>
          <div className='block11'>
            <CaseTag />
          </div>
        </Form>
      </div>
    </div>
  )
}

export default Main
