import React from 'react'
import { Badge, Menu, Dropdown, Modal, message, Popover, List, Button, Space } from 'antd'
import { connect } from 'react-redux'
import { BellOutlined, CloseCircleOutlined, PoweroffOutlined, UserOutlined, ExclamationCircleOutlined, CloseSquareOutlined } from '@ant-design/icons'
import Component, { mapStateToProps, mapDispatchToProps } from '@/components/Component'
import { setToken, updateUncompleteMessage, setUserInfo, setUncompleteMessage, setUncompleteMessageNum } from '@/redux/actions'
import style from './Header.module.less'
import TabBar from './TabBar'
import LogoutModal from './LogoutModal'
import { limitStr } from '@/assets/js/tool'

const { confirm } = Modal

class Header extends Component {
  state = {
    searchStr: '',
    uncompleteNum: 0,
    messageListvisible: false,
    modifyPasswordVisible: false,
    isExpire: false,
    expireInfo: ''
  }

  changeSearchStr (v) {
    this.setState({
      searchStr: v.target.value
    })
  }

  // 定时获取未处理消息
  loopGetUncompleteMessage () {
    this.timer = setTimeout(() => {
      this.props.updateUncompleteMessage()
    }, 60 * 1000)
  }

  // 清除定时获取未读消息
  unloopGetUncompleteMessage () {
    this.timer && clearTimeout(this.timer)
    this.props.setUncompleteMessage([])
    this.props.setUncompleteMessageNum(0)
  }

  initExpire () {
    const d = this.props.userInfo
    const isExpire = d.is_expire === 1
    this.setState({
      expireInfo: d.expire_info,
      isExpire,
      modifyPasswordVisible: isExpire
    })
  }

  componentDidMount () {
    this.props.updateUncompleteMessage()
    this.loopGetUncompleteMessage()
    // this.initExpire()
  }

  componentWillUnmount () {
    this.unloopGetUncompleteMessage()
  }

  renderClearBtn () {
    if (this.state.searchStr.length) {
      return (
        <div className={style['search__clear-box']}>
          <div className={style['search__clear-box__btn']}>
            <CloseCircleOutlined onClick={() => this.setState({ searchStr: '' })} />
          </div>
        </div>
      )
    }
  }

  // 退出登录
  logoutHandle () {
    confirm({
      title: '提示',
      icon: <ExclamationCircleOutlined />,
      content: '您确定要退出登录吗?',
      okText: '确定',
      cancelText: '取消',
      onOk: () => {
        this.logoutRequest()
      },
      onCancel () {}
    })
  }

  // 退出登录请求
  logoutRequest () {
    const hide = message.loading('退出中', 120)
    // const { realname, mobile } = this.props.userInfo
    this.$post('/api/common/logout').then(res => {
      hide()
      if (res.status === 1) {
        message.success(res.message, 1, () => {
          this.props.clearWindow()
          this.props.setToken('')
          this.props.setUserInfo({})
          this.props.setUncompleteMessage([])
          this.props.setUncompleteMessageNum(0)
          this.props.history.push('/login')
        })
      } else {
        message.error(res.message)
      }
    }, () => {
      hide()
    })
  }

  selectMenu (key) {
    if (key === 'logout') {
      this.logoutHandle()
    } else if (key === 'modifyPassword') {
      this.setState({
        modifyPasswordVisible: true
      })
    }
  }

  openMessageList () {
    this.setState({ messageListvisible: false })
    this.props.addWindow({ id: 'MessageList', title: '消息列表', componentName: 'MessageList' })
  }

  handleMessageListVisibleChange (v) {
    this.setState({ messageListvisible: v })
  }

  toDealHandle (d) {
    this.setState({ messageListvisible: false })
    let route = d.route ? d.route : null
    if (!route) {
      return message.warning('处理地址不存在')
    }
    try {
      route = JSON.parse(route)
    } catch (e) {
      return message.warning('获取处理地址错误')
    }
    const component = route.component || route.type
    if (component === 'caseDetail') {
      const { id, operate } = route.meta
      this.props.addWindow({ id: `CaseDetail-${id}`, title: `案件详情-${route.title}`, componentName: 'CaseDetail', forceRefresh: false, meta: { id, operate, editable: true } })
    } else if (component === 'MyCaseExchange') {
      this.props.addWindow({ id: 'MyCaseExchange', title: route.title, componentName: 'MyCaseExchange', forceRefresh: false, meta: route.meta })
    } else if (component === 'CaseExchangeDetail') {
      this.props.addWindow({ id: `CaseExchangeDetail-${route.meta.id}`, title: route.title, componentName: 'CaseExchangeDetail', forceRefresh: false, meta: route.meta })
    } else if (component === 'PartyEffectiveDocumentApply') {
      this.props.addWindow({ id: 'PartyEffectiveDocumentApply', title: d.title, componentName: 'PartyEffectiveDocumentApply', forceRefresh: false, meta: route.meta })
    } else if (component === 'ArbitratorAnnualDetail') {
      this.props.addWindow({ id: `ArbitratorAnnualDetail-${route.meta.id}`, title: d.title, componentName: 'ArbitratorAnnualDetail', forceRefresh: false, meta: route.meta })
    }
    this.setMessageReaded(d.read_datetime, d.id)
  }

  // 设置未读信息未已读
  setMessageReaded (time, id) {
    if (time) { return false }
    this.$put(`/api/xapc/message/read/${id}`)
      .then(res => {
        if (res.status === 1) {
          this.props.updateUncompleteMessage()
        } else {
          message.warning(res.message)
        }
      })
  }

  renderUnreadList () {
    return (
      <div className={style['message-list']}>
        <List
          size='small'
          dataSource={this.props.uncompleteMessageList}
          renderItem={(item, i) => (
            <List.Item className={style['message-line']} onClick={() => this.toDealHandle(item)}>
              <span>
                {i + 1}、{item.title}
              </span>
            </List.Item>
          )}
        />
        <div className={style['message-more']}>
          <Button type='link' onClick={() => this.openMessageList()}>查看全部</Button>
        </div>
      </div>
    )
  }

  render () {
    const menu = (
      <Menu onClick={v => this.selectMenu(v.key)}>
        <Menu.Item key='modifyPassword'>
          <span>修改密码</span>
        </Menu.Item>
        <Menu.Divider />
        <Menu.Item key='logout'>
          <Space size={4}>
            <PoweroffOutlined className={style['out-icon']} />
            <span>退出</span>
          </Space>
        </Menu.Item>
      </Menu>
    )
    return (
      <div className={style.header}>
        <LogoutModal
          visible={this.state.modifyPasswordVisible} hide={() => {
            this.setState({ modifyPasswordVisible: false })
            if (this.state.isExpire) {
              this.logoutRequest()
            }
          }} history={this.props.history} isExpire={this.state.isExpire} expireInfo={this.state.expireInfo}
        />
        <div className={style['header-content']}>
          <TabBar />
          <div className={style['other-block']}>
            <div className={style['close-all']} onClick={() => this.props.clearWindow(true)}>
              <CloseSquareOutlined />
            </div>
            {/* <div className={style.search}>
              <div className={style.search__input}>
                <input type='text' value={this.state.searchStr} onChange={(...args) => this.changeSearchStr(...args)} placeholder='案件查询...' />
              </div>
              {this.renderClearBtn()}
              <div className={style.search__btn}>
                <SearchOutlined />
              </div>
            </div> */}
            <div className={style.bell}>
              <Popover
                placement='bottomRight' title='待办提醒' content={this.renderUnreadList()} trigger='click' visible={this.state.messageListvisible}
                onVisibleChange={(e) => this.handleMessageListVisibleChange(e)}
              >
                <div className={style.bell__inner}>
                  <Badge count={this.props.uncompleteNum}>
                    <div className={style.bell__inner__content}>
                      <BellOutlined />
                    </div>
                  </Badge>
                </div>
              </Popover>
            </div>
            <Dropdown overlay={menu} trigger={['click']}>
              <div className={style.person}>
                <div className={style.person__icon}>
                  <div className={style.person__icon__bg}>
                    <UserOutlined />
                  </div>
                </div>
                <span className={style.person__name}>{limitStr(this.props.userInfo.realname, 10) || ''}</span>
              </div>
            </Dropdown>
          </div>
        </div>
      </div>
    )
  }
}

const mapState = mapStateToProps(function (state) {
  return {
    userInfo: state.basic.userInfo,
    uncompleteMessageList: state.basic.uncompleteMessageList,
    uncompleteNum: state.basic.uncompleteNum
  }
})

const mapDispatch = mapDispatchToProps(function (dispatch) {
  return {
    setToken: i => dispatch(setToken(i)),
    setUserInfo: i => dispatch(setUserInfo(i)),
    updateUncompleteMessage: () => dispatch(updateUncompleteMessage(dispatch)),
    setUncompleteMessage: i => dispatch(setUncompleteMessage(i)),
    setUncompleteMessageNum: i => dispatch(setUncompleteMessageNum(i))
  }
})

export default connect(mapState, mapDispatch)(Header)
