import React, { Component } from 'react'
import FreeScrollBar from 'react-free-scrollbar'
import { connect } from 'react-redux'
import { cloneDeep } from 'lodash'
import * as I from '@ant-design/icons'
import { addWindow, addScrollEvent } from '@/redux/actions'
import style from './Sider.module.less'
import { companyName } from '@/assets/js/config'

class Sider extends Component {
  state = {
    menus: [],
    isReady: false,
    top: 0
  }

  // 是否展开菜单
  toggleFirstMenu (index) {
    const menus = this.state.menus.slice()
    const menu = menus[index]
    if (menu.children && menu.children.length > 0) {
      menu.showChildren = !menu.showChildren
      this.setState({
        menus
      })
    } else {
      this.openPage(menu)
    }
  }

  /**
   * 子菜单是否展示中
   */
  isChildrenSelected (list) {
    const goal = list.find(li => li.id === this.props.activeWindowId)
    return goal ? style.active : ''
  }

  /**
   * 从props中拷贝菜单
   */
  cloneMenus () {
    this.setState({
      menus: cloneDeep(this.props.menus)
    })
  }

  /**
   * 打开菜单
   */
  openPage ({ id = '', title = '', componentName = '', closable = true, meta = {}, isHome = false }) {
    this.props.addWindow({ id, title, componentName, closable, meta, isHome })
  }

  /**
   * 渲染菜单列表
   */
  renderMenuList () {
    return this.state.menus.map((menu, i) => {
      let Icon = null
      const blockClass = [style.menu__firstMenu]
      if (menu.icon && I[menu.icon]) {
        Icon = I[menu.icon]
      } else {
        Icon = I.FileTextOutlined
      }
      return (
        <li key={`i-${menu.id}`} className={this.isChildrenSelected(menu.children)}>
          <div className={blockClass.join(' ')}>
            <div onClick={() => this.toggleFirstMenu(i)}>
              {Icon ? <div className={style.menu__icon}><Icon /></div> : null}
              <span className={style.firstmenu__title}>{menu.title}</span>
              <div className={style.menu__direction} v-if='item.children.length'>
                {this.renderArrow(menu)}
              </div>
            </div>
            {this.renderSubMenu(menu)}
          </div>
        </li>
      )
    })
  }

  // 渲染箭头
  renderArrow (menu) {
    const Arrow = menu.showChildren ? I.UpOutlined : I.DownOutlined
    if (!menu.children.length) {
      return null
    }
    return (
      <Arrow className={style['menu__direction-icon']} />
    )
  }

  // 渲染子菜单
  renderSubMenu (menu) {
    if (menu.showChildren) {
      return (
        <ul className={style['sub-menu']}>
          {this.renderSubMenuList(menu)}
        </ul>
      )
    }
  }

  // 渲染子菜单列表
  renderSubMenuList (menu) {
    return menu.children.map((item, i) => {
      const activeClass = item.id === this.props.activeWindowId ? style.active : ''
      return (
        <li className={activeClass} key={`${i}-${item.id}`} onClick={() => this.openPage(item)}>{item.title}</li>
      )
    })
  }

  initDesktop () {
    const { windows, userInfo } = this.props
    const { id, desktop } = userInfo
    if (id && windows.length === 0) {
      this.openPage({ id: '0', title: '桌面', componentName: desktop || 'DefaultDesktop', closable: false, isHome: true })
    }
    // this.openPage({ id: 'ArbitratorAnnualDetail', title: '仲裁员年审-2024年07月08日~2024年07月31日', componentName: 'ArbitratorAnnualDetail', meta: { id: 'F4D7CCAB3073929AFE39831AECA5B1EE' } })
    // this.openPage({ id: 'ArbitratorListVersion', title: '仲裁员电子名册', componentName: 'ArbitratorListVersion' })
    // this.openPage({ id: 'ArbitratorDetail-99E1A01BD7F4AF8B27BCD445D9D62F6A', title: '仲裁员详情', componentName: 'ArbitratorEdit', meta: { id: '99E1A01BD7F4AF8B27BCD445D9D62F6A' } })
    // this.openPage({ id: 'CaseDetail-ACA2929617B61E62F84EF635293BAC5F', title: 'XA20200079', componentName: 'CaseDetail', meta: { id: 'ACA2929617B61E62F84EF635293BAC5F', editable: true, operate: 'jianmian' } })
  }

  componentDidUpdate (prev) {
    // 当菜单为空时，根据store中的菜单初始化state中的菜单
    const { menus, userInfo, windows } = this.props
    if (prev.menus !== menus) {
      this.cloneMenus()
    }
    if (!prev.userInfo.id && userInfo.id && windows.length === 0) {
      this.initDesktop()
    }
  }

  componentDidMount () {
    this.initDesktop()
    // this.openPage({ id: '0', title: '桌面', componentName: 'CaseDetail', meta: { id: '6D8CFC0360C9139E73E47CB60EC900B1', editable: true } }) // A6D38625CBF9955AE7110476297FFAB8
    this.setState({
      isReady: true
    })
  }

  // 渲染菜单滚动区域
  renderMenus () {
    if (this.state.isReady) {
      return (
        <FreeScrollBar className='mock'>
          <ul className={style.menu}>
            {this.renderMenuList()}
          </ul>
        </FreeScrollBar>
      )
    }
  }

  render () {
    return (
      <div className={style.sider}>
        <div className={style.sider__header}>
          <img src={require('../../assets/img/logo1.png')} />
          <h1>{companyName}</h1>
        </div>
        <div className={style.sider__content}>
          {this.renderMenus()}
        </div>
      </div>
    )
  }
}

function mapStateToProps (state) {
  return {
    userInfo: state.basic.userInfo,
    menus: state.pageMeta.menus,
    activeWindowId: state.pageMeta.activeWindowId,
    windows: state.pageMeta.windows
  }
}

function mapDispatchToProps (dispatch) {
  return {
    addWindow: (i) => dispatch(addWindow(i)),
    addScrollEvent: (i) => dispatch(addScrollEvent(i))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Sider)
